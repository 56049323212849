import React from "react";
import useStyles from "./styled";
import Button from "@mui/material/Button";
import imgLogo from "../../assets/images/logo.png";

const Home = () => {
  const classes = useStyles();

  return (
    <main className={classes.main}>
      <div className={classes.ctn}>
        <img src={imgLogo} alt="Boomdoo" className={classes.imgLogo} />
        <h1 className={classes.title}>
          <span>Inicia tu comercio con </span>
          <b>inteligencia. </b>
          <span>Comienza con </span>
          <span className={classes.textColorPrimary}>BoomDoo</span>
        </h1>
        <section className={classes.services}>
          <h3>Te ofrecemos:</h3>
          <ol>
            <li>
              <span>Bot inteligente 24/7 🤖💡⏰</span>
            </li>
            <li>
              <span>Bot personalizado 🤖🎨✨</span>
            </li>
            <li>
              <span>Integrado con tu sistema de negocio: 🔗💼📊</span>
            </li>
          </ol>
        </section>
        <Button
          onClick={() => window.open("https://api.whatsapp.com/send/?phone=%2B14155238886&text=join+sitting-team&type=phone_number&app_absent=0", "_blank")}
          fullWidth
          size="large"
          variant="contained"
          type="submit"
          className={classes.btn}
        >
          QUIERO PROBAR EL BOT
        </Button>
      </div>
    </main>
  );
};

export default Home;

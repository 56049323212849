import Home from './pages/Home';
import { createTheme, ThemeProvider, Theme } from "@mui/material/styles";

const customTheme: Theme = createTheme({
  palette: {
    primary: {
      main: "#8a3efe",
      contrastText: "white",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          height:"54px",
          borderRadius: 12,
          textTransform: "capitalize"
        },
      }, 
    }, 
  },
});

function App() {
  return (
    <ThemeProvider theme={customTheme}>
      <Home />
    </ThemeProvider>
  );
}

export default App;

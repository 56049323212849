import { makeStyles } from "@mui/styles";
import theme from "../../themes";

const useStyles = makeStyles({
    main:{
        background: 'linear-gradient(to right, #efe6ff, #b1a9e8)',
    },
    ctn: {
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        width:"calc(100% - 40px)",
        paddingLeft:"20px",
        paddingRight:"20px",
        height:'100vh',
        flexDirection:'column',
        [theme.breakpoints.up("sm")] : {
            maxWidth:"600px",
            margin:"0px auto",
            paddingBottom:"130px",
        }
    },
    imgLogo:{
        width:'108px',
        height:'108px',
        background:'transparent',
        borderRadius:'50%'
    },
    title:{
        textAlign:'center'
    },
    textColorPrimary:{
        color:'#8a3efe'
    },
    services:{
        '& ol':{
            listStyle:'none',
            padding:'0px',
        },
        '& ol li':{
            lineHeight:'2em'
        }
    },
    btn:{
        backgroundColor:'black !important'
    }

});

export default useStyles;

